import { useState, useEffect } from 'react';
import axios from 'axios';
import ENV from '../env.json';

const localStorage: any =
  typeof window !== 'undefined' ? (window as any).localStorage : {};

const toBoolean = (value: string | undefined) => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return undefined;
};

export function useGdpr() {
  const [isEu, setEu] = useState(toBoolean(localStorage.isEu));
  const [acceptedCookies, setAcceptedCookies] = useState<boolean | undefined>(
    toBoolean(localStorage.acceptedCookies)
  );

  useEffect(() => {
    if (localStorage.isEu === 'true') {
      return;
    }

    axios.get(`${ENV.cloudFunctionsDomain}/geo-checkEu`).then(({ data }) => {
      setEu(data.isEu);
      localStorage.isEu = data.isEu;
    });
  }, []);

  useEffect(() => {
    // By default, these things are set to anonymize_ip true and
    // allowAdFeatures false. But if the user opts in and out again,
    // we need to handle re-setting them back
    localStorage.acceptedCookies = acceptedCookies;
  }, [acceptedCookies]);

  return { isEu, acceptedCookies, setAcceptedCookies };
}
