import React from 'react';
import { Button } from '@material-ui/core';
import { CtaClickLabel, sendCtaClickEvent } from '../../tracking';
import { EarlyAccessDialog } from '../EarlyAccessDialog';
import { GetStartedDialog } from '../GetStartedDialog';

export const SignupButton = ({
  text,
  variant,
  location
}: {
  text?: string | React.ReactNode;
  variant?: 'contained' | 'outlined';
  location?: CtaClickLabel;
}) => {
  const cta = text || 'Request early access';
  const v = variant || 'contained';
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <>
      <Button
        size="large"
        color="primary"
        variant={v}
        style={{
          marginBottom: '6px',
          fontWeight: 700,
          height: '48px'
        }}
        onClick={() => {
          sendCtaClickEvent({ label: location || 'content' });
          setDialogOpen(true);
        }}
      >
        {cta}
      </Button>
      <EarlyAccessDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

export const SignupButtonWithChoice = ({
  text,
  variant,
  location
}: {
  text?: string | React.ReactNode;
  variant?: 'contained' | 'outlined';
  location?: CtaClickLabel;
}) => {
  const cta = text || 'Request early access';
  const v = variant || 'contained';
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <>
      <Button
        size="large"
        color="primary"
        variant={v}
        style={{
          marginBottom: '6px',
          fontWeight: 700,
          height: '48px'
        }}
        onClick={() => {
          sendCtaClickEvent({ label: location || 'content' });
          setDialogOpen(true);
        }}
      >
        {cta}
      </Button>
      <GetStartedDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};
