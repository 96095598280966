import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ENV from '../../env.json';
import { SanityPost } from '../../types/Article';
import { compact } from 'lodash-es';

const FPR = `
  (function(w){w.fpr=w.fpr||function(){w.fpr.q = w.fpr.q||[];w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);};})(window);
fpr("init", {cid:"lu4o9v6w"});
fpr("click");
`;

const PLAUSIBLE = `
  window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }
`;

const SAVVY_CAL = `
  window.SavvyCal=window.SavvyCal||function(){(SavvyCal.q=SavvyCal.q||[]).push(arguments)};
`;
const SAVVY_CAL_INIT = `
  window.SavvyCal('init');
`;

export const SEO = ({
  title,
  description,
  image,
  socialSharingImage,
  pathname,
  isArticle = false,
  noIndex = false,
  publishedDate,
  lastUpdated,
  author
}: {
  title?: string;
  description?: string;
  image?: string;
  socialSharingImage?: string;
  pathname?: string;
  isArticle?: boolean;
  noIndex?: boolean;
  publishedDate?: string;
  lastUpdated?: string;
  author?: SanityPost['author'];
}) => {
  const defaultTitle =
    'Affiliate Content Optimization and Link Tracking Tool · Affilimate';
  const defeaultDescription =
    'Track your affiliate links and commissions in one place and increase revenue with affiliate growth tools.';
  const titleTemplate = '%s · Affilimate';
  const twitterUsername = 'affilimateio';
  const defaultImage = '/images/social-preview-image.png';
  let img = socialSharingImage || image || defaultImage;
  const isBrowser = typeof window !== 'undefined';
  const queryParams = isBrowser
    ? new URLSearchParams(window.location.search)
    : null;

  useEffect(() => {
    if (isBrowser && queryParams) {
      // If it's an old affiliate program link, redirect it to the new version
      if (queryParams.has('aid') && !queryParams.has('fpr')) {
        const fpr = queryParams.get('aid')?.toLowerCase();
        const fp_sid = queryParams.get('tid');
        let params = `${window.location.search}&fpr=${fpr}`;
        if (fp_sid) {
          params += `&fp_sid=${fp_sid}`;
        }

        window.location.search = params;
      }
    }
  }, [isBrowser]);

  if (img.includes('cover-photos')) {
    const ASSET_URL = 'https://assets.affilimate.com';
    img = img.substr('/images/'.length, img.length);

    if (img.startsWith('/')) {
      img = img.substr(1, img.length);
    }
    img = `${ASSET_URL}/${img}`;
  }

  const seo = {
    title: title || defaultTitle,
    description: description || defeaultDescription,
    image:
      img.includes('assets') || img.includes('jobs')
        ? img
        : `${ENV.website.baseUrl}${img}`,
    url: `${ENV.website.baseUrl}${pathname || ''}`
  };

  const authorWebsites = author
    ? compact([
        author.twitter && `https://twitter.com/${author.twitter}`,
        author.instagram && `https://instagram.com/${author.instagram}`,
        author.website,
        author.linkedin
      ]).map((u) => `"${u}"`)
    : [];

  return (
    <Helmet
      title={seo.title}
      titleTemplate={
        seo.title !== defaultTitle && !isArticle ? titleTemplate : '%s'
      }
    >
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta name="description" content={seo.description} />

      {ENV.website.baseUrl.indexOf('firebaseapp.com') !== -1 || noIndex ? (
        <meta name="robots" content="noindex" />
      ) : null}
      <meta name="image" content={seo.image} />
      <link rel="dns-prefetch" href="https://fonts.gstatic.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <meta name="p:domain_verify" content="7bd87f7fc3b2b4522c006a4b24ac9866" />
      <meta
        property="og:site_name"
        content="Affilimate · Affiliate Dashboard and Link Tracking Tool"
      />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.url && <link rel="canonical" href={seo.url} />}
      {isArticle && <meta property="og:type" content="article" />}
      {isArticle && author && (
        <script type="application/ld+json">
          {`{"@context": "https://schema.org", "@type": "Article", "headline": "${
            seo.title
          }", "author": { "@type": "Person", "name": "${
            author.name
          }", "jobTitle": "${author.jobTitle}", ${
            lastUpdated ? `"dateModified": "${lastUpdated}",` : ''
          } "url": "https://affilimate.com/blog/authors/${
            author.slug.current
          }/", "sameAs": [${authorWebsites.join(
            ','
          )}] }, "publisher": { "name": "Affilimate", "@type": "Organization", "url": "https://affilimate.com" } }`}
        </script>
      )}
      {isArticle && (
        <meta
          property="article:author"
          content="https://www.facebook.com/affilimate/"
        />
      )}
      {isArticle && (
        <meta
          property="article:author"
          content="https://www.facebook.com/affilimate/"
        />
      )}
      {isArticle && (
        <meta property="article:published_time" content={publishedDate} />
      )}

      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="" />
      {twitterUsername && (
        <meta name="twitter:creator" content={`@${twitterUsername}`} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      <link rel="shortcut icon" href="/favicon.png" />
      {isBrowser && (
        <script
          async
          defer
          data-domain="affilimate.com"
          src="https://plausible.io/js/script.js"
        />
      )}
      {isBrowser && <script>{PLAUSIBLE}</script>}
      {isBrowser && <script>{FPR}</script>}
      {isBrowser && (
        <script src="https://cdn.firstpromoter.com/fpr.js" async></script>
      )}
      {isBrowser && (
        <script async src="https://embed.savvycal.com/v1/embed.js"></script>
      )}
      {isBrowser && <script>{SAVVY_CAL}</script>}
      {isBrowser && <script>{SAVVY_CAL_INIT}</script>}
    </Helmet>
  );
};
