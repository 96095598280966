import React from 'react';
import { sendCtaClickEvent } from '../tracking';
import { Button } from '@material-ui/core';
import { CALENDAR_LINK } from '../constants/demo-link';
import { ArrowRightCircle } from 'react-feather';

export const DemoButton = ({
  text,
  variant,
  style
}: {
  text?: string | React.ReactNode;
  variant?: 'contained' | 'outlined' | 'text';
  style?: React.CSSProperties;
}) => {
  const cta = text || 'Get a demo';
  const styles = style || {};
  const v = variant || 'outlined';

  return (
    <Button
      variant={v}
      color="primary"
      href={CALENDAR_LINK}
      target="_blank"
      style={{
        height: '48px',
        borderWidth: '2px',
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.05)',
        marginBottom: '6px',
        fontWeight: 700,
        ...styles
      }}
      onClick={() => {
        sendCtaClickEvent({ label: 'demo' });
      }}
    >
      {cta} &nbsp;{' '}
      <ArrowRightCircle
        size={17}
        style={{ position: 'relative', top: '1px' }}
      />
    </Button>
  );
};
