import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { sendCtaClickEvent } from '../tracking';
import { CALENDAR_LINK } from '../constants/demo-link';
import { EarlyAccessDialog } from './EarlyAccessDialog';
import { ArrowRightCircle } from 'react-feather';

export const GetStartedDialog = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [creatorDialogOpen, setCreatorDialogOpen] = React.useState(false);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <strong>What type of business do you have?</strong>
      </DialogTitle>
      <DialogContent>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          href={CALENDAR_LINK}
          size="large"
          target="_blank"
          onClick={() => {
            sendCtaClickEvent({ label: 'popup-mediaco' });
          }}
        >
          <strong>Media company</strong> &nbsp;&nbsp;{' '}
          <ArrowRightCircle
            size={16}
            style={{ position: 'relative', top: '1px' }}
          />
        </Button>
        <br />
        <br />
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => {
            sendCtaClickEvent({ label: 'popup-creator' });
            setCreatorDialogOpen(true);
          }}
        >
          <strong>Creator</strong>
        </Button>
        <br />
        <br />
      </DialogContent>
      <EarlyAccessDialog
        open={creatorDialogOpen}
        onClose={() => setCreatorDialogOpen(false)}
      />
    </Dialog>
  );
};
