let gtag: any;
let clarity: any;
let Plausible: any;

if (typeof window !== 'undefined') {
  gtag = (window as any).gtag;
  clarity = (window as any).clarity;
  Plausible = (...args: any) => {
    console.log('Sending to plausible', args);
    (window as any).plausible(...args);
  };
} else {
  gtag = () => {};
  Plausible = () => {};
}

/*
 * To attribute a custom dimension during a session/for a user,
 * ensure to set it after they have logged in.
 *
 *
   gtag('config', 'GA_MEASUREMENT_ID', {
     'custom_map': {'dimension<Index>': 'dimension_name'}
   });

  dimension<Index> = 'dimension1';
  dimension_name = 'user_id';

  And then use:

  gtag('event', 'any_event_name', { 'dimension_name': dimension_value });

 *
 */

let userId: string;
let spaceId: string | null;

export type IEvent = {
  category: string;
  action:
    | 'Add product'
    | 'Add product (error)'
    | 'Click'
    | 'Close'
    | 'Copy'
    | 'Delete products'
    | 'Delete products (error)'
    | 'External click'
    | 'Edit product'
    | 'Edit product (error)'
    | 'Filter partner'
    | 'Filter timeframe'
    | 'Import'
    | 'Import (error)'
    | 'Open'
    | 'Request screenshot'
    | 'Request screenshot (error)'
    | 'Search'
    | 'Sort'
    | 'Scan'
    | 'Scan (error)'
    | 'Revision: select'
    | 'Revision: snap timeframe'
    | 'Toggle'
    | 'Verify domain (error)'
    | 'Verify domain'
    | 'Submit'
    | 'Subscribe from Navigator'
    | 'Subscribe from TopNav';
  label: string;
  value?: number;
};

export const setSpaceIdDimension = (currentSpaceId: string) => {
  spaceId = currentSpaceId;
  gtag('config', 'GA_MEASUREMENT_ID', {
    custom_map: { dimension2: 'space_id' }
  });
};

export const setUserDimension = (
  currentUserId: string,
  displayName: string,
  email: string,
  spaceId: string
) => {
  userId = currentUserId;

  if (gtag) {
    gtag('config', 'GA_MEASUREMENT_ID', {
      custom_map: { dimension1: 'user_id' },
      user_id: userId
    });
  }

  if (clarity) {
    try {
      clarity('set', 'userId', currentUserId);
      clarity('set', 'spaceId', spaceId);
      clarity('set', 'name', displayName);
      clarity('set', 'email', email);
    } catch (err) {
      // do nothing
    }
  }
};

type ICategory = {
  [key: string]: string;
};

// Create category names based on the url the user is on
export const toAppCategory = () => {
  const url = (window as any).location.pathname;
  const urlsToCategories = {
    Analytics: 'analytics',
    Pages: 'pages/all',
    'Pages > Details': 'pages/detail',
    'Pages > Heatmap': 'pages/heatmap',
    Products: 'products/all',
    'Products > Details': 'products/detail',
    Partners: 'partners',
    Scan: 'scan',
    Checks: 'schedules',
    'Settings > Profile': 'settings/profile',
    'Settings > Notifications': 'settings/notifications',
    'Settings > Change password': 'settings/change-password'
  } as ICategory;

  return (
    Object.keys(urlsToCategories)
      .filter((key) => url.indexOf(urlsToCategories[key] as any) !== -1)
      .pop() || 'Unknown'
  );
};

export const sendEvent = ({ category, action, label, value }: IEvent) => {
  if (userId) {
    try {
      gtag('event', action, {
        event_category: category,
        event_label: label,
        user_id: userId,
        space_id: spaceId,
        value
      });
      console.log({ category, action, label, user_id: userId, value });
    } catch (err) {}
    return;
  }

  try {
    gtag('event', action, {
      event_category: category,
      event_label: label,
      value
    });
    console.log({ category, action, label });
  } catch (err) {}
};

export type CtaClickLabel =
  | 'nav'
  | 'footer'
  | 'prefooter'
  | 'prefooter-creator'
  | 'prefooter-mediaco'
  | 'content'
  | 'demo'
  | 'popup-mediaco'
  | 'popup-creator';

export const sendCtaClickEvent = ({ label }: { label: CtaClickLabel }) => {
  try {
    Plausible('CTA Click', { props: { location: label } });
    (window as any).fathom.trackGoal('LVFIHYPB', 0);
    (window as any).sa_event('cta_click');
    (window as any)._paq.push(['trackGoal', 3]);
    console.log('Tracked CTA Click');
  } catch (err) {}
};

export const sendBlogSubscribeEvent = ({ location }: { location: string }) => {
  try {
    Plausible('Blog Subscribe', { props: { location } });
    (window as any).fathom.trackGoal('9KUHJ1XG', 0);
    (window as any).sa_event('blog_subscribe');
    (window as any)._paq.push(['trackGoal', 4]);
    console.log('Tracked Blog Subscribe');
  } catch (err) {}
};

export type OnboardingEvent =
  | 'Onboarding: Create account'
  | 'Onboarding: Fill profile'
  | 'Onboarding: Click demo'
  | 'Onboarding: Add first integration'
  | 'Onboarding: Verify first website'
  | 'Onboarding: Import first links'
  | 'Onboarding: Payment';

export const sendOnboardingEvent = ({
  event,
  value
}: {
  event: OnboardingEvent;
  value: number;
}) => {
  try {
    if (event === 'Onboarding: Create account') {
      (window as any).fathom.trackGoal('VNPLBZKT', value);
    } else if (event === 'Onboarding: Fill profile') {
      (window as any).fathom.trackGoal('ETDFRHDO', value);
    } else if (event === 'Onboarding: Click demo') {
      (window as any).fathom.trackGoal('UPS2YBFX', value);
    } else if (event === 'Onboarding: Verify first website') {
      (window as any).fathom.trackGoal('MJ2V4K1S', value);
    } else if (event === 'Onboarding: Import first links') {
      (window as any).fathom.trackGoal('ZBXAZHBL', value);
    } else if (event === 'Onboarding: Add first integration') {
      (window as any).fathom.trackGoal('1ARFZDJB', value);
    } else if (event === 'Onboarding: Payment') {
      (window as any).fathom.trackGoal('YY8SRKJ5', value);
    }
    console.log('Tracked onboarding event', event, value);
  } catch (err) {}
};

export const sendLeadEvent = ({ page, url }: { page: string; url: string }) => {
  try {
    gtag('event', 'Submit', {
      event_category: 'Leads',
      event_label: page
    });
  } catch (err) {}
};
