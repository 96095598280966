import React from 'react';
import styled from '../../styled';
import { Typography } from '@material-ui/core';
import { DemoButton } from '../DemoButton';
import { SignupButton } from '../SignupButton';
import { COLORS } from '../../themes/color';

const HeroSvg = () => (
  <div style={{ position: 'absolute', bottom: 0, zIndex: 0 }}>
    <svg
      width="1440"
      height="430"
      viewBox="0 0 1440 430"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1">
        <path
          opacity="0.2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-52 288.041C43.3963 288.041 48.5675 366.216 143.964 366.216C239.36 366.216 239.36 222.547 334.756 222.547C430.153 222.547 430.153 306.201 525.549 306.201C620.945 306.201 620.945 178.962 716.341 178.962C811.737 178.962 811.738 3.45825 907.134 3.45825C1002.53 3.45825 1002.53 184.704 1097.93 184.704C1193.32 184.704 1181.93 100.348 1264.11 100.348C1346.28 100.348 1351.32 319.799 1446.72 319.799C1446.95 319.799 1447.21 430 1446.72 430C1434.98 430 -52 430 -52 430V288.041Z"
          fill="url(#paint0_linear_11382_12585)"
        />
        <path
          d="M-51.6016 288.155C44.2159 288.155 44.2159 366.762 140.033 366.762C235.851 366.762 235.851 222.299 331.668 222.299C427.486 222.299 427.486 306.415 523.303 306.415C619.121 306.415 619.121 178.473 714.938 178.473C810.756 178.473 810.756 2 906.573 2C1002.39 2 1002.39 184.247 1098.21 184.247C1194.03 184.247 1180.06 98.5731 1259.91 98.5731C1339.76 98.5731 1347.18 320.123 1443 320.123"
          stroke="#FFDBA1"
          strokeOpacity="0.37"
          strokeWidth="4"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_11382_12585"
          x1="-131.753"
          y1="-49.8597"
          x2="-131.753"
          y2="430"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.339495" stopColor="#FFDBA1" />
          <stop offset="1" stopColor="white" stopOpacity="0.01" />
        </linearGradient>
      </defs>
    </svg>
  </div>
);

const SectionArea = styled('div')`
  text-align: center;
  max-width: 750px;
  margin: 69px auto 0;
  color: ${COLORS.TEXT};
  z-index: 1;

  h2 {
    font-size: 48px;
    margin-bottom: 48px;
  }

  p {
    color: ${COLORS.TEXT};
  }
`;

const Container = styled('div')`
  background-color: ${COLORS.PAPER_DARK};
  position: relative;
  display: grid;
  align-items: center;
  overflow: hidden;
  padding: ${(p) => p.theme.spacing(2)}px;
`;

const DoubleSection = styled('div')`
  z-index: 1;
  display: grid;
  max-width: 1000px;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  grid-row-gap: ${(p) => p.theme.spacing(2)}px;
  align-items: center;
  margin-bottom: 48px;

  @media (max-width: 700px) {
    margin: ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
  }
`;

const Section = styled('div')`
  background-color: ${COLORS.PAPER};
  padding: ${(p) => p.theme.spacing(4)}px;
  border-radius: 8px;
`;

export const InfoMessage = styled('div')`
  border-radius: 8px;
  background-color: #bae7ff;
  color: #096dd9;
  font-size: 1rem;
  padding: 12px 24px;

  a {
    border-bottom: 1px solid #096dd9;
  }
`;

export const WebinarForm = () => {
  return (
    <Container>
      <SectionArea>
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          style={{ fontWeight: 900 }}
        >
          Maximize your affiliate revenue with content insights for publishers
        </Typography>
      </SectionArea>
      <DoubleSection>
        <Section>
          <Typography variant="body1" component="p" paragraph>
            <strong>Media companies</strong>
          </Typography>
          <Typography
            variant="body2"
            component="p"
            color="textSecondary"
            paragraph
          >
            Achieve the next level of commerce revenue growth with
            editor-friendly, on-page optimization tools.
          </Typography>
          <DemoButton text="Book a demo" variant="contained" />
        </Section>
        <Section>
          <Typography variant="body1" component="p" paragraph>
            <strong>Professional creators</strong>
          </Typography>
          <Typography
            variant="body2"
            component="p"
            color="textSecondary"
            paragraph
          >
            Get the insights and tools every professional creator needs to
            unlock fast and systematic affiliate growth.
          </Typography>
          <SignupButton text="Request early access" variant="outlined" />
        </Section>
      </DoubleSection>
      <HeroSvg />
    </Container>
  );
};
