import React, { useState, useEffect } from 'react';
import { Typography, Button } from '@material-ui/core';
import { Link } from 'gatsby';
import { useGdpr } from '../../hooks/useGdpr';
import styled from '../../styled';

const BUTTON_GROUP_WIDTH = 160;

const Bar = styled('div')`
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  color: white;
  padding: 0 36px;

  @media (max-width: 800px) {
    padding: 6px 12px;
  }
`;

const ButtonWrapper = styled('div')`
  text-align: right;
  width: ${BUTTON_GROUP_WIDTH}px;
`;

const TextWrapper = styled('div')`
  width: calc(100% - ${BUTTON_GROUP_WIDTH}px);

  a {
    text-decoration: underline;
  }
`;

const AcceptButton = styled(Button)`
  color: white;
  background-color: transparent;
  text-decoration: underline;

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
`;

const CancelButton = styled(Button)`
  color: #666;
`;

export const CookieBar = () => {
  const { isEu, acceptedCookies, setAcceptedCookies } = useGdpr();

  if (!isEu || typeof acceptedCookies === 'boolean') {
    return null;
  }

  return (
    <Bar>
      <TextWrapper>
        <Typography style={{ fontSize: '13px' }}>
          This website uses cookies. Learn more in our{' '}
          <Link to="/docs/privacy-policy/">privacy policy</Link>.
        </Typography>
      </TextWrapper>
      <ButtonWrapper>
        <CancelButton
          onClick={() => {
            setAcceptedCookies(false);
          }}
        >
          Reject
        </CancelButton>
        <AcceptButton
          color="default"
          variant="contained"
          onClick={() => {
            setAcceptedCookies(true);
          }}
        >
          Accept
        </AcceptButton>
      </ButtonWrapper>
    </Bar>
  );
};
