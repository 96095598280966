import { createMuiTheme } from '@material-ui/core';

let theme = createMuiTheme({
  typography: {
    fontFamily: `'Lato', sans-serif`,
    body1: {
      fontSize: 18
    },
    body2: {
      fontSize: 16
    }
  },
  palette: {
    primary: {
      light: '#63ccff',
      main: '#1890ff',
      dark: '#096dd9'
    }
  },
  shape: {
    borderRadius: 8
  }
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c'
      }
    },
    MuiButton: {
      label: {
        textTransform: 'initial'
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing()
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white
      }
    },
    MuiTab: {
      root: {
        textTransform: 'initial',
        margin: '0 16px',
        minWidth: 0,
        [theme.breakpoints.up('md')]: {
          minWidth: 0
        }
      },
      labelContainer: {
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0
        }
      }
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing()
      }
    },
    MuiPaper: {
      elevation1: {
        border: '1px solid #eee',
        boxShadow:
          '0px 1px 1px -1px rgba(0,0,0,0.1), 2px 5px 4px -1px rgba(0,0,0,0.01)'
      },
      elevation3: {
        border: '1px solid #eee',
        boxShadow:
          '0px 1px 2px 0px rgba(0,0,0,0.1), 2px 5px 7px 2px rgba(0,0,0,0.01)'
      },
      elevation8: {
        border: '1px solid #eee',
        boxShadow:
          '0px 1px 5px 1px rgba(0,0,0,0.05), 2px 5px 9px 2px rgba(0,0,0,0.01)'
      }
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '16px'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '13px',
        borderRadius: 4,
        padding: '8px',
        backgroundColor: '#111111'
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854'
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20
        }
      }
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32
      }
    },
    MuiTableCell: {
      paddingDense: {
        padding: theme.spacing(),
        paddingRight: theme.spacing()
      }
    },
    // @ts-ignore
    MuiToggleButtonGroup: {
      root: {
        // border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius
      },
      selected: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    },
    // @ts-ignore
    MuiToggleButton: {
      root: {
        background: 'none',
        color: theme.palette.primary.main,
        border: 'none',
        '&:hover': {
          backgroundColor: `${theme.palette.primary.dark} !important`,
          color: `${theme.palette.primary.contrastText} !important`
        }
      },
      selected: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.primary.contrastText} !important`
      }
    }
  },
  props: {
    MuiTab: {
      disableRipple: true
    }
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48
    }
  }
};

export default theme;
