import React, { useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import styled from '../styled';
import { CioIdentify } from '../domainTypes/customerio';
import ENV from '../env.json';

const StyledLink = styled('a')`
  border-bottom: 1px solid #ddd;
`;

const TOTAL_STEPS = 3;
const REVENUE_RANGES = [
  {
    label: 'Under $1K',
    value: 0
  },
  {
    label: '$1K-5k',
    value: 1000
  },
  {
    label: '$5-15k',
    value: 5000
  },
  {
    label: '$15-25k',
    value: 15000
  },
  {
    label: '$25-50k',
    value: 25000
  },
  {
    label: '$50k+',
    value: 50000
  }
];

export const EarlyAccessDialog = ({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState('');
  const [channelType, setChannelType] = useState('');
  const [channelUrl, setChannelUrl] = useState('');
  const [revenueRange, setRevenueRange] = useState<number | null>(null);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitData = async () => {
    const user: CioIdentify = {
      email: email,
      id: email,
      lists: ['creator_waitlist'],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      source: window.location.pathname,
      referrer: document.referrer || '',
      waitlist_channel: channelType,
      waitlist_url: channelUrl,
      cookies: JSON.stringify(Cookies.get()),
      waitlist_min_revenue: revenueRange!
    };

    setIsSubmitting(true);

    return axios
      .post(`${ENV.cloudFunctionsDomain}/mail-createLeadV2`, user)
      .then(() => {
        setIsSubmitting(true);
      })
      .catch((_) => {
        setIsSubmitting(false);
      });
  };

  const onHandleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (step === 0) {
      if (!email) {
        setError('Please enter your email address');
        return;
      }
      if (email.includes('gmail') || email.includes('hotmail')) {
        setError(
          'Please use your business email address. We do not accept free or single-use email addresses.'
        );
        return;
      }
      setError(null);
      setStep(1);
      return;
    } else if (step === 1) {
      if (!channelType) {
        setError('Please select a channel type from the options above.');
        return;
      }
      if (!channelUrl) {
        setError(
          'Please enter your channel URL, so we can verify your ownership.'
        );
        return;
      }
      setError(null);
      setStep(2);
      return;
    } else if (step === 2) {
      if (!revenueRange) {
        setError(
          'Please select a revenue range, so we can prioritize your application.'
        );
        return;
      }
      if (!termsAgreed) {
        setError(
          'Please agree to the terms and privacy policy, so we can contact you.'
        );
        return;
      }
    }
    setError(null);
    // Submit request to API
    await submitData();
    setStep(3);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setStep(0);
      }}
    >
      <DialogTitle>
        <strong>
          Apply for early access to the Affilimate creator platform
        </strong>
      </DialogTitle>
      <form onSubmit={onHandleSubmit}>
        <DialogContent>
          <LinearProgress
            variant="determinate"
            value={step < TOTAL_STEPS ? ((step + 1) / TOTAL_STEPS) * 100 : 100}
          />
          <br />
          {step === 0 && (
            <div>
              <Typography variant="body1" component="p" gutterBottom>
                <strong>What's your business email?</strong>
              </Typography>
              <Typography variant="body2" component="p" gutterBottom>
                This should be your creator business email, not a personal
                email. We'll use this to send your invite, so make sure it's
                valid!
              </Typography>
              <br />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="email"
                id="email"
                label="Your business email"
                placeholder="yourname@yourwebsite.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                autoComplete="email"
                autoFocus
              />
            </div>
          )}
          {step === 1 && (
            <div>
              <Typography variant="body1" component="p" gutterBottom>
                <strong>
                  Where are you earning most successfully from affiliate links
                  today?
                </strong>
              </Typography>
              <Typography variant="body2" component="p" gutterBottom>
                We're looking for creators who are already earning from their
                affiliate efforts, and have an established audience.
              </Typography>
              <br />
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="channel"
                  name="channel"
                  value={channelType}
                  row
                  onChange={(e) => setChannelType(e.target.value)}
                >
                  <FormControlLabel
                    value="Blog"
                    control={<Radio color="primary" />}
                    label="Blog"
                  />
                  <FormControlLabel
                    value="YouTube"
                    control={<Radio color="primary" />}
                    label="YouTube"
                  />
                  <FormControlLabel
                    value="Newsletter"
                    control={<Radio color="primary" />}
                    label="Newsletter"
                  />
                  <FormControlLabel
                    value="Social media"
                    control={<Radio color="primary" />}
                    label="Social media"
                  />
                </RadioGroup>
              </FormControl>
              {channelType && (
                <>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={channelUrl}
                    onChange={(e) => setChannelUrl(e.target.value)}
                    type="url"
                    id="channelUrl"
                    label={`Your ${channelType} URL`}
                    placeholder="https://"
                    name="channelUrl"
                    autoComplete="url"
                    autoFocus
                  />
                </>
              )}
            </div>
          )}
          {step === 2 && (
            <div>
              <Typography variant="body1" component="p" gutterBottom>
                <strong>
                  How much do you earn in affiliate commissions in an average
                  month?
                </strong>
              </Typography>
              <Typography variant="body2" component="p" gutterBottom>
                This information is kept strictly confidential and helps us
                predict which features will be most useful to you.
              </Typography>
              <br />
              <br />
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="revenue"
                  name="revenue"
                  value={revenueRange}
                  row
                  onChange={(e) => setRevenueRange(parseInt(e.target.value))}
                >
                  {REVENUE_RANGES.map((range) => (
                    <FormControlLabel
                      key={range.value}
                      value={range.value}
                      control={<Radio color="primary" />}
                      label={range.label}
                    />
                  ))}
                </RadioGroup>
                {revenueRange === 0 && (
                  <Typography
                    variant="caption"
                    component="p"
                    gutterBottom
                    color="error"
                  >
                    Creators under $1K/mo in affiliate revenue{' '}
                    <strong>don't currently qualify</strong> for our early
                    access program. Check back soon!
                  </Typography>
                )}
              </FormControl>
            </div>
          )}
          {error && (
            <Typography
              variant="caption"
              component="p"
              gutterBottom
              color="error"
              style={{ marginTop: '32px' }}
            >
              <strong>There was an error!</strong> {error}
            </Typography>
          )}
          {step === 3 && (
            <div>
              <Typography variant="body1" component="p" paragraph>
                <strong>Thanks for applying!</strong>
              </Typography>
              <Typography variant="body2" component="p" paragraph>
                Thanks for sharing more details about your creator business!
              </Typography>

              <Typography variant="body2" component="p" paragraph>
                Our team will reach out if you qualify for one of the limited,
                early access slots. You won’t be notified if you don’t qualify,
                but we will email you when the platform is publicly available
                for registration.
              </Typography>

              <Typography variant="body2" component="p" paragraph>
                Thanks again, all the best with your business.
              </Typography>

              <Typography variant="body2" component="p" paragraph>
                - The Affilimate Team
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: '24px',
            marginTop: step < TOTAL_STEPS ? '50px' : undefined
          }}
        >
          {step !== TOTAL_STEPS && (
            <>
              <div>
                {step === TOTAL_STEPS - 1 && (
                  <FormControlLabel
                    style={{ position: 'relative', left: '-20px' }}
                    control={
                      <Checkbox
                        checked={termsAgreed}
                        color="primary"
                        onChange={(e) => setTermsAgreed(e.target.checked)}
                        name="termsAgreed"
                      />
                    }
                    label={
                      <span style={{ fontSize: '12px', lineHeight: '12px' }}>
                        Agree to the{' '}
                        <StyledLink href="/qa/terms-of-service/">
                          Terms of Service
                        </StyledLink>{' '}
                        and{' '}
                        <StyledLink href="/qs/privacy-policy/">
                          Privacy Policy
                        </StyledLink>
                        .
                      </span>
                    }
                  />
                )}
              </div>
              <div
                style={{
                  width: '225px',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Button
                  size="large"
                  disabled={step === 0}
                  onClick={() => {
                    setStep(step > 0 ? step - 1 : 0);
                  }}
                  variant="text"
                >
                  Back
                </Button>
                <Button
                  size="large"
                  type="submit"
                  style={{ minWidth: '150px' }}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || revenueRange === 0}
                >
                  {step === TOTAL_STEPS - 1 ? 'Apply!' : 'Next'}
                </Button>
              </div>
            </>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};
