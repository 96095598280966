import React, { useState } from 'react';
import { Menu as MenuIcon, X as CloseIcon } from 'react-feather';
import { Link } from 'gatsby';
import styled from '../../styled';
import { Button, IconButton } from '@material-ui/core';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import ENV from '../../env.json';
import { GetStartedDialog } from '../GetStartedDialog';
import { COLORS } from '../../themes/color';

const NavBarInner = styled('div')`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  color: ${COLORS.TEXT};
  transition: 0.7s;
  padding: 0 ${(p) => p.theme.spacing(2)}px;

  a {
    transition: none;
  }

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(1)}px;
    grid-template-columns: 90px 1fr;
  }
`;

const Menu = styled('ul')`
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  color: ${COLORS.TEXT};
  text-align: center;
`;

const MenuItem = styled('li')`
  display: inline-block;
  padding: 24px 12px;

  @media (max-width: 800px) {
    padding: 12px;
  }

  a {
    font-size: 16px;
    position: relative;
  }

  a:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: -6px;
    left: 0;
    background-color: #000;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  a:hover:before {
    transform: scaleX(1);
  }
`;

const RightColumn = styled('div')`
  text-align: right;
  position: relative;
  top: 2px;

  @media (max-width: 800px) {
    font-size: 12px;
    top: 0;
  }

  a {
    position: relative;

    &.MuiButton-root:before {
      background-color: transparent;
      width: 0;
      transform: none;
    }

    &.MuiButton-root {
      transform: scale(1);
      transition: transform 0.1s ease;
    }
    &.MuiButton-root:hover {
      transform: scale(1.1);
    }
  }

  a:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: -6px;
    left: 0;
    background-color: #000;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  a:hover:before {
    transform: scaleX(1);
  }

  .hide-on-mobile {
    @media (max-width: 800px) {
      display: none !important;
    }
  }

  .hide-on-desktop {
    display: none;

    @media (max-width: 800px) {
      display: inline-block;
    }
  }
`;

const SubMenuContainer = styled(MenuItem)`
  position: relative;
  display: inline-block;
  transition: 0.1s opacity linear;

  &:hover .submenu {
    display: grid;
    opacity: 1;
    transition: 0.1s opacity linear;
    z-index: 1;
  }

  .submenu {
    display: none;
    top: 56px;
    opacity: 0;
    z-index: -1;
    position: absolute;
    transform: translate(-130px, 0px);
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    z-index: 1;
    background-color: ${COLORS.PAPER};
    color: ${COLORS.TEXT};
    width: 700px;
    margin: 0;
    padding-inline-start: 0;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
    border: 1px solid ${COLORS.PAPER_DARK};
    overflow: hidden;
    grid-template-columns: 1fr 1fr;

    li {
      list-style-type: none;
      text-align: left;

      a {
        display: flex;
        align-items: center;
        padding: ${(p) => p.theme.spacing(2)}px;

        svg {
          margin-right: 12px;
        }

        &:hover {
          background-color: ${COLORS.PAPER_DARK};
        }

        &:before {
          display: none;
        }
      }
    }
  }
`;

const MenuLinks = () => {
  return (
    <Menu>
      <MenuItem>
        <Link to="/">Home</Link>
      </MenuItem>
      <SubMenuContainer>
        <Link to="/product/">Features</Link>
      </SubMenuContainer>
      <MenuItem>
        <Link to="/integrations/">Integrations</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/pricing/">Pricing</Link>
      </MenuItem>
      <MenuItem>
        <Link to="/demo/">Demo</Link>
      </MenuItem>
    </Menu>
  );
};

const DesktopToggle = styled('div')`
  display: inline-block;
  @media (max-width: 800px) {
    display: none;
  }
`;
const MobileToggle = styled('div')`
  display: none;

  @media (max-width: 800px) {
    display: inline-block;
  }
`;

const Navigation = () => {
  const isMobile = useMediaQuery('(max-width: 800px)');
  const [mobileMenu, toggleMobileMenu] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const seeMenu = (function () {
    if (!isMobile) {
      return false;
    }
    return mobileMenu;
  })();

  return (
    <div
      style={{
        top: 0,
        position: 'fixed',
        zIndex: 100,
        width: '100%',
        backgroundColor: COLORS.PAPER
      }}
    >
      <NavBarInner>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <MobileToggle>
            <IconButton onClick={() => toggleMobileMenu(!mobileMenu)}>
              {!mobileMenu ? (
                <MenuIcon color="black" />
              ) : (
                <CloseIcon color="black" />
              )}
            </IconButton>
          </MobileToggle>
          <Link to="/">
            <img
              src="https://storage.googleapis.com/affilimate-assets/logos/logo-black-full.png"
              height="20"
              width="89"
              title="Affilimate Home Page"
              alt="Affilimate"
            />
          </Link>
        </div>
        <DesktopToggle>
          <MenuLinks />
        </DesktopToggle>
        <RightColumn>
          <a
            href={ENV.app.baseUrl}
            className="hide-on-mobile"
            style={{
              display: 'inline-block',
              marginLeft: '12px',
              marginRight: '18px'
            }}
          >
            Log in
          </a>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            Get a demo
          </Button>
        </RightColumn>
      </NavBarInner>
      {seeMenu && <MenuLinks />}
      <GetStartedDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </div>
  );
};

export default Navigation;
