export const AFFILIATE_ID_PARAM = 'aid';
export const TRACKING_ID_PARAM = 'tid';

export const AFFILIATE_COOKIE_NAME = 'affilimate-referral';
export const AFFILIATE_COOKIE_EXPIRATION_DAYS = 30;

export type AffiliateCookie = {
  [AFFILIATE_ID_PARAM]: string;
  [TRACKING_ID_PARAM]?: string;
  ref: string; // where did people come from?
  ua: string; // user agent
  ts: number; // cookie creation date in unix ms
  v: number;
};
