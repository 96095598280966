import ENV from './env.json';
import {
  AffiliateCookie,
  AFFILIATE_ID_PARAM,
  TRACKING_ID_PARAM,
  AFFILIATE_COOKIE_NAME,
  AFFILIATE_COOKIE_EXPIRATION_DAYS
} from './domainTypes/affiliate/cookie';

import Cookies from 'js-cookie';

const getSingleParamOrEmpty = (name: string) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const getAffiliateParamsFromUrl = () => {
  return {
    affiliateId: getSingleParamOrEmpty(AFFILIATE_ID_PARAM),
    trackingId: getSingleParamOrEmpty(TRACKING_ID_PARAM)
  };
};

export const trySetAffiliateCookie = () => {
  if (typeof window === 'undefined') {
    return;
  }
  const { trackingId, affiliateId } = getAffiliateParamsFromUrl();
  if (!affiliateId) {
    return;
  }

  const v: AffiliateCookie = {
    [AFFILIATE_ID_PARAM]: affiliateId,
    [TRACKING_ID_PARAM]: trackingId,
    ref: document.referrer || '',
    ts: Date.now(),
    ua: window.navigator.userAgent,
    v: 1
  };
  Cookies.set(AFFILIATE_COOKIE_NAME, v, {
    expires: AFFILIATE_COOKIE_EXPIRATION_DAYS,
    domain: ENV.app.cookieDomain,
    sameSite: 'strict'
  });
};
